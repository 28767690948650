.container {
  width: 75%;
  margin: 0 3em;
  position: absolute;
  left: 17%;
}

.heading {
  margin-bottom: 1.5em;
  color: #262626;
  font-size: 28px;
}

.totalLoss {
  text-align: center;
  background-color: white;
  padding: 10px;
  font-size: larger;
  color: #262626;
}

.barGraph {
  position: relative;
  height: 400px;
  background-color: white;
  padding: 3.5em 2.5em 1em 2.5em;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
}

.lineGraph {
  position: relative;
  height: 300px;
  background-color: white;
  padding: 3.5em 2.5em 1em 2.5em;
  margin-top: 2em;
  margin-bottom: 2em;
  text-align: center;
}

.resetButton {
  position: absolute !important;
  top: 5px !important;
  right: 5px !important;
}

.downloadIcon,
.resetIcon {
  margin-right: 5px;
  font-size: 18px !important;
}

.buttonText {
  font-size: 14px !important;
}

.exportButton {
  position: absolute !important;
  left: 5px !important;
  top: 5px !important;
  margin-bottom: 1em !important;
}

.graphHeading {
  position: absolute !important;
  top: 25px !important;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold !important;
  font-size: 15px !important;
}
