.container {
  width: 100%;
  margin-top: 5%;
}

.heading {
  margin-bottom: 1.5em;
  color: #262626;
  font-size: 28px;
}
