body {
  margin: 0;
  background-color: #cfdeff !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ag-cell-wrapper,
.ag-header-cell-label {
  justify-content: center;
}

.ag-theme-quartz,
.ag-theme-quartz-dark {
  --ag-header-foreground-color: white !important;
  --ag-header-background-color: black !important;
  --ag-cell-horizontal-border: solid #dddddd !important;
}

.ag-theme-quartz .ag-header-cell,
.ag-theme-quartz-dark .ag-header-cell {
  font-weight: bolder !important;
}

.ag-header-cell {
  border-right: 0.1px solid #dddddd !important;
}

.ag-header-group-cell-with-group {
  font-weight: bolder;
  justify-content: center;
  border-bottom: 0.1px solid #dddddd !important;
  border-right: 0.1px solid #dddddd !important;
}

.ag-pinned-left-header {
  border-right: 0.1px solid #dddddd !important;
}

.exportButton:hover {
  cursor: pointer;
}
