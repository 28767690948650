.form {
  width: 100%;
  margin-bottom: 2em;
}

.formBox {
  display: flex;
  align-items: center;
  gap: 2em;
  margin-bottom: 2em;
}

.label {
  color: black;
  margin-right: 2em;
  color: grey;
  font-size: 16px;
}

.checkbox {
  color: grey;
}

.inputBox {
  display: flex;
  align-items: center;
}

.selectInput {
  width: 300px;
  font-size: 12px;
  z-index: 4;
}

.input {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 4em;
  font-size: 12px;
}

.submitButton {
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}
