.form {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 2em;
}

.label {
  color: black;
  margin-right: 2em;
  color: grey;
  font-size: 13px;
}

.select {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 4em;
  font-size: 12px;
}

.inputBox {
  display: flex;
  align-items: center;
}

.selectInput {
  width: 300px;
  font-size: 12px;
  z-index: 4;
}

.tableCell,
.headerTableCell,
.channelHeaderTableCell {
  border-left: 0.1px solid rgb(214, 214, 214);
}

.totalRow {
  background-color: #f5f5f5;
}

.totalRow td {
  font-weight: bold;
  font-size: 15px;
}

.channelHeaderTableCell {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3 !important;
}

.headerTableCell {
  min-width: 100px !important;
}

.channelTableCell {
  border-right: 0.1px solid rgb(214, 214, 214);
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: #f5f5f5;
}
