.container {
    width: 75%;
    margin: 0 3em;
    position: absolute;
    left: 17%;
  }
  
  .heading {
    margin-bottom: 1.5em;
    color: #262626;
    font-size: 28px;
  }