.container {
  width: 75%;
  margin: 0 3em;
  position: absolute;
  left: 17%;
}

.heading {
  margin-bottom: 1.5em;
  color: #262626;
  font-size: 28px;
}

.graph {
  min-height: 300px;
  background-color: white;
  padding: 2em;
  margin-top: 2em;
  text-align: center;
}

.currentWorkingCapital > span {
  margin-right: 3em;
}

.currentWorkingCapital {
  text-align: center;
  background-color: white;
  padding: 10px;
  font-size: larger;
  color: #262626;
}
