.csvForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
}

.uploadCsvBox {
  display: flex;
  align-items: center;
  width: 100%;
}

.modalForm {
  width: 40%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 24;
  border-radius: 5px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.prouductUpdateFormBox {
  display: flex;
  justify-content: space-evenly;
}

.subFormHeading {
  font-weight: bold !important;
  text-align: center !important;
}

.deviderBox {
  display: flex;
  gap: 5px;
  align-content: center;
  align-items: center;
}

.globalProductUpdateForm {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
}

.globalProductUpdateFormBox {
  width: 100%;
  margin: auto;
  padding: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
}

.closeModalIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.confirmationModal {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 130px;
  background-color: rgb(255, 255, 255);
  box-shadow: 24;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
}

.label {
  display: inline-block;
  width: 200px;
  color: grey;
  font-size: 14px;
  margin-right: -5em;
}

.modalLabel,
.modalCsvLabel {
  color: grey;
  font-size: 14px;
}

.modalCsvLabel {
  margin-top: -5em;
}

.input {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 4em;
  font-size: 12px;
}

.modalInput {
  width: 60%;
  border: 1px solid silver;
  border-radius: 3px;
  padding: 5px;
  font-size: 12px;
}

.modalCsvInput {
  width: 70%;
  border: 1px solid silver;
  border-radius: 3px;
  padding: 5px;
  font-size: 12px;
  margin-top: -6em;
}

.submitButton {
  display: inline-block;
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.buttonBox {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.modalSubmitButton,
.modalCsvUploadSubmitButton {
  width: 50%;
  padding: 5px 10px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}
