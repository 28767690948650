.list {
  height: 100vh;
  width: 17%;
  background-color: rgb(255, 255, 255);
  position: fixed !important;
  border: 0.1px solid #d4dce7;
  overflow: scroll;
}

.listItemText {
  color: #637381;
}

.listItemIcon {
  color: #637381;
}

.listItemButton {
  margin-top: 10px !important;
}

.listItemButton:hover {
  border-radius: 16px;
}

.listItemButton:hover .listItemIcon {
  font-size: 26px;
  color: black;
}

.listItemButton:hover .listItemText {
  color: black;
}

.activeListItemButton {
  margin-top: 10px !important;
  border-radius: 16px !important;
  background-color: rgb(0, 0, 0) !important;
}

.activeListItemButton .listItemIcon {
  color: white;
}

.activeListItemButton .listItemText {
  font-weight: bold !important;
  color: white;
}

.imageDiv {
  position: fixed !important;
  left: 2%;
  top: 842px;
  margin-top: 1em;
  text-align: center;
}

.gblIcon {
  width: 100%;
  height: 75px;
}
