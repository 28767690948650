.container {
  width: 75%;
  margin: 0 3em;
  position: absolute;
  left: 17%;
}

.heading {
  margin-bottom: 1.5em;
  color: #262626;
  font-size: 28px;
}

.dateBox {
  display: flex;
  gap: 2em;
  margin-bottom: 2em;
}

.dates {
  text-align: center;
  background-color: white;
  padding: 0 5px 5px;
  font-size: larger;
  color: #262626;
  width: 170px;
}

.dates > span,
b {
  font-size: 12px;
}

.filterProducts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 5px 1em;
  margin-bottom: 1em;
}
