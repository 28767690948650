.container {
  width: 75%;
  margin: 0 3em;
  position: absolute;
  left: 17%;
}

.heading {
  margin-bottom: 1.5em;
  color: #262626;
  font-size: 28px;
}

.errorMessage {
  width: 500px;
  text-align: center;
}

.formBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.updateProductButton {
  width: 250px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.buttonBox {
  display: flex;
  gap: 1em;
}
