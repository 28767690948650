.form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
}

.label {
  width: 200px;
  color: black;
  margin-right: 2em;
  color: grey;
  font-size: 14px;
}

.submitButton {
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.middleText {
  margin-right: 10px;
  margin-top: 2px;
}

.inputBox {
  display: flex;
}

.input {
  border: none;
  border-radius: 2px;
  width: 200px;
  padding: 5px;
  margin-right: 1em;
}

.selectInput {
  border: none;
  border-radius: 2px;
  width: 210px;
  padding: 5px;
  margin-right: 1em;
}

.inputOcc {
  width: 60px;
  border: none;
  border-radius: 2px;
  padding: 5px;
  margin-right: 1em;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 2px 10px;
  margin-top: -1.5em;
}

.searchField {
  width: 250px;
}
