.inputBox {
  display: flex;
  align-items: center;
}

.label {
  color: black;
  margin-right: 2em;
  color: grey;
  font-size: 14px;
}

.input {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 2em;
  font-size: 12px;
}
