.formBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}

.label {
  color: black;
  margin-right: 1em;
  color: grey;
  font-size: 14px;
}

.inputBox {
  display: flex;
  align-items: center;
}

.selectInput {
  width: 300px;
  font-size: 12px;
  z-index: 4;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}

.buttonsBox {
  display: flex;
  gap: 1em;
}

.confirmationButtonBox {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.submitButton {
  display: inline-block;
  width: 150px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
  margin-top: 2em;
}

.modalForm {
  width: 40%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 24;
  border-radius: 5px;
  padding-top: 30px;
  padding-bottom: 50px;
}

.closeModalIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.csvForm {
  width: 60%;
  text-align: center;
  margin: auto;
}

.modalCsvLabel {
  color: grey;
  margin-right: 2em;
  font-size: 14px;
}

.modalCsvInput {
  width: 65%;
  border: 1px solid silver;
  border-radius: 3px;
  padding: 5px;
  font-size: 12px;
  margin-top: -6em;
  margin-bottom: 2em;
}

.modalCsvUploadSubmitButton {
  width: 50%;
  text-align: center;
  padding: 5px 10px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}
