.form {
  display: flex;
  gap: 1.5em;
  margin-bottom: 2em;
}

.inputBox {
  display: flex;
}

.label {
  color: black;
  margin-right: 5em;
  color: grey;
  font-size: 14px;
}

.input {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 4em;
  font-size: 12px;
}

.submitButton {
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}

.toolTipTable th,
.toolTipTable td {
  border: 0.1px solid #00000024;
  padding: 1px 20px;
  text-align: center;
  font-size: 10px;
}

.toolTipTable {
  position: relative;
}

.toolTipTable th {
  font-weight: bold;
  font-size: 10px;
  color: white !important;
}

.toolTipRowHead {
  background-color: black !important;
}
