.form {
  display: flex;
  width: 100%;
  margin-bottom: 2em;
}

.inputBox {
  display: flex;
  align-items: center;
}

.label {
  color: black;
  margin-right: 2em;
  color: grey;
  font-size: 13px;
}

.select {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 4em;
  font-size: 12px;
}

.submitButton {
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.table {
  width: 100%;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}

.table_head {
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: black;
  color: white;
}

.channel_column_head {
  position: sticky;
  left: 0;
  top: 0;
  z-index: 2;
  background-color: black;
}

.channel_columns {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: whitesmoke;
  min-width: 150px;
}

.totalRow {
  background-color: #f5f5f5;
}

.totalRow td{
  font-weight: bold;
  font-size: 15px;
}

#footer {
  position: sticky;
  bottom: 0;
  z-index: 1;
  background-color: white;
}

#saveButton {
  background-color: black;
  color: white;
  font-weight: bold;
  margin: 10px 15px;
}

#saveButton[disabled] {
  background-color: rgb(163, 163, 163);
  cursor: not-allowed;
  pointer-events: all !important;
}

.conf_score_container {
  position: relative;
  right: 0;
  margin-left: 5px;
  display: inline;
  border: 1px solid silver;
  text-align: right;
  width: fit-content;
}

.cell {
  position: relative;
}

.quantity {
  padding: 1px 5px;
  width: 200px;
}

.confidence_score {
  padding: 1px 5px;
}

.id {
  color: rgb(255, 0, 0);
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}
