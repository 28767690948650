.container {
  width: 75%;
  margin: 0 3em;
  position: absolute;
  left: 17%;
}

.heading {
  margin-bottom: 1.5em;
  color: #262626;
  font-size: 28px;
}

.channelFormBox {
  display: flex;
  justify-content: space-between;
}

.modalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 400px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 24;
  padding: 3em 4em 4em 4em;
}

.channelCreateFormHeading {
  text-align: center;
}

.createNewChannelButton {
  width: 250px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}
