.form {
  display: flex;
  gap: 1em;
  margin-bottom: 2em;
}

.label {
  width: 250px;
  font-size: 14px;
  color: grey;
  margin-right: -11em;
}

.inputBox {
  display: flex;
  align-items: center;
}

.dateRangePicker {
  width: 270px;
}

.selectInput {
  width: 300px;
  font-size: 12px;
  z-index: 4;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}
