.form {
  display: flex;
  flex-direction: column;
  gap: 1.5em;
  margin-bottom: 2em;
}

.label {
  width: 250px;
  font-size: 14px;
  color: rgb(36, 36, 36);
}

.inputBox {
  display: flex;
  align-items: center;
}

.input {
  border: none;
  border-radius: 2px;
  width: 200px;
  padding: 5px;
  margin-right: 1em;
}

.selectInput {
  border: none;
  border-radius: 2px;
  width: 210px;
  padding: 5px;
  margin-right: 1em;
}

.submitButton {
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.tableCell,
.headerTableCell,
.channelHeaderTableCell {
  border-left: 0.1px solid rgb(214, 214, 214);
}

.channelHeaderTableCell {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3 !important;
}

.channelTableCell {
  border-right: 0.1px solid rgb(214, 214, 214);
  position: sticky;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
}
