.form {
  display: flex;
  gap: 1.5em;
  margin-bottom: 2em;
}

.formBox {
  display: flex;
  align-items: center;
  gap: 2em;
}

.inputBox {
  display: flex;
}

.label {
  color: black;
  margin-right: 5em;
  color: grey;
  font-size: 14px;
}

.input {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 4em;
  font-size: 12px;
}

.submitButton {
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.tableRow {
  cursor: pointer;
}

.featureRow {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 3;
  width: 100% !important;
  height: 50px !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.searchField {
  width: 250px;
  margin-right: 1em !important;
}

.tableRowHead {
  position: sticky !important;
  top: 50px !important;
  z-index: 1;
}

.tableCellHead {
  background-color: black !important;
  color: white !important;
  font-weight: bold !important;
}

.tableCellHead,
.tableCell {
  border-left: 0.1px solid rgb(222, 222, 222) !important;
}

.tableRow,
.tableCell {
  height: 50px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.downloadIcon {
  margin-right: 8px;
}

.footerRow {
  position: sticky !important;
  bottom: 0 !important;
  background-color: white !important;
  z-index: 1;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}
