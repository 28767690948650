.form {
  display: flex;
  gap: 1em;
  margin-bottom: 2em;
}

.label {
  width: 250px;
  font-size: 14px;
  color: grey;
  margin-right: -11em;
}

.inputBox {
  display: flex;
  align-items: center;
}

.input {
  border: none;
  border-radius: 2px;
  width: 200px;
  padding: 5px;
  margin-right: 1em;
}

.selectInput {
  width: 300px;
  font-size: 12px;
  margin-left: 1em;
  z-index: 4;
}

.submitButton {
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.dateRangePicker {
  width: 270px;
}
