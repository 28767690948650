.form {
  display: flex;
  gap: 3em;
  align-items: center;
  margin-bottom: 2em;
}

.label {
  width: 250px;
  font-size: 14px;
  color: grey;
  margin-right: -13em;
}

.inputBox {
  display: flex;
  align-items: center;
}

.datePicker {
  width: 270px;
}

.tableHead {
  position: sticky;
  top: 0;
}

.tableHeadRow {
  background-color: #f4f4f4;
}

.tableHeadCell {
  font-size: 14.5px !important;
  font-weight: bold !important;
}

.tableSubHead {
  background-color: black;
  color: white !important;
  font-weight: bold !important;
  font-size: 16px !important;
}

.overAllRow td {
  font-weight: bold !important;
}

.table th,
.table td {
  border: 1px solid #dddddd;
  padding: 8px;
  text-align: center;
}

.toolTipTable th,
.toolTipTable td {
  border: 0.1px solid #00000024;
  padding: 1px 20px;
  text-align: center;
  font-size: 10px;
}

.toolTipTable th {
  font-weight: bold;
  font-size: 12px;
}

.boldRow td,
.boldCol {
  font-size: large !important;
  font-weight: bold !important;
}

.scoreCard {
  width: 100%;
  display: flex;
  gap: 2em;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 30px;
  font-weight: bold;
}

.score {
  background-color: white;
  padding: 0 20px;
}
