.form {
  width: 100%;
  margin-bottom: 2em;
}

.label {
  color: black;
  margin-right: 2em;
  color: grey;
  font-size: 13px;
}

.select {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 4em;
  font-size: 12px;
}

.tableRow {
  cursor: pointer;
}

.tableRowHead {
  position: sticky;
  top: 0;
  z-index: 2;
}

.tableSubRowHead {
  position: sticky;
  top: 39px;
  z-index: 2;
}

.tableCellHead,
.tableRowHead {
  padding: 0.5em 1em !important;
}

.tableCellHead {
  background-color: black !important;
  color: white !important;
  font-weight: bold !important;
}

.tableCellHead,
.tableCell {
  border-left: 0.1px solid rgb(222, 222, 222) !important;
}

.tableRow,
.tableCell {
  height: 50px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.collapseTable {
  width: 90% !important;
  margin: auto !important;
}

.collapseTableRowHead {
  height: 20px !important;
  padding: 0 !important;
}

.collapseTableCellHead {
  background-color: black !important;
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
}

.collapseTableRowHead,
.collapseTableCellHead {
  height: 35px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.collapseTableCellHead,
.collaspeTableCell {
  border-left: 0.1px solid rgb(222, 222, 222) !important;
}

.collapseTableRow,
.collaspeTableCell {
  height: 30px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}

.totalRow td {
  font-weight: bold;
  font-size: 16px;
  border-left: 0.1px solid rgb(222, 222, 222) !important;
}

.totalRow td {
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 1;
  border-top: 0.1px solid rgb(222, 222, 222) !important;
}
