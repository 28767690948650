.form {
  width: 100%;
  margin-bottom: 2.5em;
}

.label {
  display: inline-block;
  width: 200px;
  color: grey;
  font-size: 14px;
}

.input {
  width: 270px;
  border: 1px solid silver;
  border-radius: 3px;
  border: none;
  padding: 5px;
  margin-right: 4em;
  font-size: 12px;
}

.submitButton {
  width: 200px;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  border-radius: 3px;
  font-size: 12px;
  cursor: pointer;
}

.channelCreateForm {
  display: flex;
  flex-direction: column;
  gap: 1em;
  color: black;
}

.createChannelFormInput {
  width: 100%;
  max-height: 300px;
}

.createChannelButton {
  width: 100%;
  padding: 6px;
  background-color: black;
  color: #ffffff;
  font-weight: 600;
  border: none;
  margin-top: 1em;
  border-radius: 3px;
  font-size: 15px;
  cursor: pointer;
}

.dropdownOptions {
  display: flex;
  align-items: center;
  margin: 3px;
}

.channelName {
  font-size: 13px;
  color: gray;
}

.ucNameWithChannel {
  cursor: pointer;
}

.ucName {
  margin: 5px;
}

.ucNone {
  margin-left: 10px;
}

.viewButton {
  margin-left: 1em;
}

.tableContainer {
  position: relative;
}

.customTableHeader {
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  padding: 0 10px;
}

.searchField {
  width: 250px;
}
